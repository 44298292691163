import {IEnvironment} from 'utilities';

export const environment: IEnvironment = {
  production: true,
  hostname: 'isend.no',
  ibricksApiV2: 'https://api.ibricks.no/v2', // isend doesn't exist for this api
  ibricksApiV3: 'https://api3.isend.no/v3',
  ibricksWss: 'wss://api2-dev.ibricks.no',
  login: 'https://api3.isend.no/names.nsf?login',
  upload: 'https://functions.isend.no/.netlify/functions/upload',
  imageUpload: 'https://functions.isend.no/.netlify/functions/imageupload',
  invalidateCloudfrontCache: 'https://functions.isend.no/.netlify/functions/invalidate-cache',
  cloudfrontUrl: 'https://d2n98k53uqkrwy.cloudfront.net',
  msGraphApi: 'https://graph.microsoft.com/v1.0',
  azureApi: 'https://prod-149.westeurope.logic.azure.com:443',
  contentBuilderUrl: 'https://contentbuilder.isend.no',
  translateApi: 'https://translation.googleapis.com/language/translate',
  iStudyApi: 'https://api-dev.istudy.no',
  keycloakConfig: {
    url: 'https://auth-dev.isi.no',
    realm: 'isi-api-dev',
    clientId: 'ibricks-frontend'
  }
};
